import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row, Breadcrumb, InputGroup } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Layout from '../../layout/Layout';
import Cookies from 'js-cookie';
import { API } from '../../App';
import { toast } from 'react-toastify';

const SubCategoryAdd = () => {

    const getData = async() => {
        const token = Cookies.get('ptjwt')
        const response = await API.post("/admin/category", {}, { headers: { "Authorization": `Bearer ${token}` } })
        if(response.data.status === false) {
            if(response.data.response_code===429){
                navigate("/")
            }else{
                toast.error("Something went wrong in category")
            }
        }else{
            setData(response.data.data.data)
        }
    }

    const [data,setData] = useState([])
    const [validate,setValidate] = useState(false)
    const navigate = useNavigate()
    const [inpData, setInpData] = useState({
        Category:"",
        Name:"",
        Header:"",
        Status:1
    })

    const getValue = (e) => {
        setInpData({ ...inpData, [e.target.name]: e.target.value })
    }

    const [selectedImage, setSelectedImage] = useState();

    const onImags = (event) => {
        setSelectedImage(event.target.files[0]);
    }

    const saveData = async () => {
        if (inpData.Category === "" || inpData.Name === "" || inpData.Header==="" || selectedImage == undefined) {
            setValidate(true)
        }else{
          const Form = new FormData()
          Form.append('category', inpData.Category)
          Form.append('name', inpData.Name)
          Form.append('header', inpData.Header)
          Form.append('status', inpData.Status)
          Form.append('image', selectedImage)
          const token = Cookies.get('ptjwt')
          const response = await API.post("/admin/add/sub_category", Form, { headers: { "Authorization": `Bearer ${token}` } })
          console.log(response,"addRes");
          if (response.data.status === true) {
            toast.success("Sub Category Created")
            navigate("/subcategory")
          } else {
            if (response.data.response_code === 429) {
              navigate("/")
            } else {
              toast.error('something went wrong')
            }
          }
        }
    }
    
    useEffect(() => {
        getData()
    }, [])
    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3>Create Sub Category</h3>
                <Breadcrumb className="d-none d-lg-block">
                    <Breadcrumb.Item >
                        <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item >
                        <Link to="/subcategory">Sub Category</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Create Sub Category</Breadcrumb.Item>
                </Breadcrumb>
            </div>

            <div className="page-content">
            <Form noValidate validated={validate}>
                <Row>
                    <Col xs={12}>
                        <Card className="mb-4">
                            <Card.Body>
                                <Row>
                                    <Col sm={3}>
                                        <Form.Label htmlFor="mcat" >Category</Form.Label>
                                        <Form.Select id='mcat' className='my-2' name='Category' onChange={(e) => { getValue(e) }} required>
                                            <option value="">Select Category</option>
                                            {
                                                data.map((dataVal,ind) => {
                                                    return (
                                                        <option value={dataVal._id} key={ind}>{dataVal.name}</option>
                                                    )
                                                })
                                            }

                                        </Form.Select>
                                    </Col>
                                    <Col lg={3} sm={12}>
                                        <Form.Label htmlFor="cate" >Name</Form.Label>
                                        <Form.Control type="text" id='cate' className="my-2" value={inpData.Name} name="Name" onChange={(e) => { getValue(e) }} placeholder='Enter Name' required/>
                                    </Col>
                                    <Col lg={3} sm={12}>
                                        <Form.Label htmlFor="icon" >Icon</Form.Label>
                                        <InputGroup className="my-2">
                                            <Form.Control type="file" id='icon' onChange={(e) => { onImags(e) }} required/>
                                            {selectedImage && (
                                                <img src={URL.createObjectURL(selectedImage)} alt="Image" height={40} />
                                            )}
                                        </InputGroup>
                                    </Col>
                                    <Col lg={3} sm={12}>
                                        <Form.Label htmlFor="cate" >Header</Form.Label>
                                        <Form.Control type="text" id='cate' className="my-2" name='Header' value={inpData.Header} onChange={(e) => { getValue(e) }} placeholder='Enter Header' required/>
                                    </Col>
                                    <Col lg={3} sm="auto">
                                        <Form.Label htmlFor="status" className='d-block mb-2'>Status</Form.Label>
                                        <BootstrapSwitchButton id="status" checked={inpData.Status === 1 ? true : false} onChange={(checked) => { if (checked == true) { setInpData({ ...inpData, ['Status']: 1 }) } else { setInpData({ ...inpData, ['Status']: 0 }) } }} onlabel='Enable' offlabel='Disabled' size="sm" width={100} />
                                    </Col>
                                </Row>
                            </Card.Body>
                            <Card.Footer className="text-end">
                                <Button variant="primary" className="me-3" onClick={saveData}>Save</Button>
                                <Link to="/subcategory">
                                    <Button variant="secondary">Cancle</Button>
                                </Link>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Form>
            </div>
        </Layout>
    )
}

export default SubCategoryAdd