import Cookies from "js-cookie";
import React, { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { API, AuthContext } from "../App";
import Login from "../pages/Login";

function PrivateRoute({ children }) {
    // const tokenAuth = async () => {
    const { token,setToken } = useContext(AuthContext);
    const [homePage, sethomePage] = useState()
    useEffect(() => {
        setToken(Cookies.get('ptjwt'))
        getHomePage()
    }, [token])
    const getHomePage = async () => {
        const response = await API.post("/admin/home", {}, { headers: { "Authorization": `Bearer ${token===Cookies.get('ptjwt')?token:Cookies.get('ptjwt')}` } })
        sethomePage(response)
    }
    if (token && homePage?.data?.status) {
        return children;
    }else if (token && !homePage) {
        return <div className='loader' ></div>
    }else{
        return <Login />;
    }
    //     }
    //   const loginToken = tokenAuth() // get login details
    //   return loginToken ? children : <Navigate to="/" />;
}

export default PrivateRoute;