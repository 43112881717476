import { Routes, Route, HashRouter, Navigate, BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'boxicons/css/boxicons.css';
import Home from './pages/Home';
// import Login from './pages/Login';
import Category from './pages/Category/Category';
import CategoryAdd from './pages/Category/CategoryAdd';
import CategoryEdit from './pages/Category/CategoryEdit';
import CategoryView from './pages/Category/CategoryView';
import SubCategory from './pages/SubCategory/SubCategory';
import SubCategoryAdd from './pages/SubCategory/SubCategoryAdd';
import SubCategoryEdit from './pages/SubCategory/SubCategoryEdit';
import './App.css';
import './utilities.css';
import SubCategoryView from './pages/SubCategory/SubCategoryView';
import UserCycles from './pages/UserCycles/UserCycles';
import HealthNprofiles from './pages/HealthNProfiles/HealthNprofiles';
import GraphReports from './pages/GraphReports/GraphReports';
import Plan from './pages/Plans/Plan';
import PlanAdd from './pages/Plans/PlanAdd';
import PlanEdit from './pages/Plans/PlanEdit';
import PlanView from './pages/Plans/PlanView';
import Questions from './pages/Questions/Questions';
import QuestionsAdd from './pages/Questions/QuestionsAdd';
import QuestionsEdit from './pages/Questions/QuestionsEdit';
import QuestionsView from './pages/Questions/QuestionsView';
import UserSubscription from './pages/UserSubscriptions/UserSubscription';
import Forgot from './pages/Webview/Forgot';
import axios from 'axios'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { createContext, useState } from 'react';
import PrivateRoute from './protectedRoute/ProtectedRoute.jsx'
import PageNotFound from './pages/PageNotFound';
export const API = axios.create({ baseURL: "https://periodtrackertest.vasundharaapps.com/" })

export const AuthContext = createContext();

function App() {
  const [token, setToken] = useState(Cookies.get('ptjwt'));

  return (
    <>
      
      <AuthContext.Provider value={{ token, setToken }}>
        <BrowserRouter>
          <Routes>
            {/* <Route path="/home" element={<Home/>} /> */}
            <Route path="/" element={<PrivateRoute><Home/></PrivateRoute>} />
            <Route path="/category" element={<PrivateRoute><Category /></PrivateRoute>} />
            <Route path="/category/add" element={<PrivateRoute><CategoryAdd /></PrivateRoute>} />
            <Route path="/category/edit/:_id" element={<PrivateRoute><CategoryEdit /></PrivateRoute>} />
            <Route path="/category/view/:_id" element={<PrivateRoute><CategoryView /></PrivateRoute>} />

            <Route path="/subcategory" element={<PrivateRoute><SubCategory /></PrivateRoute>} />
            <Route path="/subcategory/add" element={<PrivateRoute><SubCategoryAdd /></PrivateRoute>} />
            <Route path="/subcategory/edit/:_id" element={<PrivateRoute><SubCategoryEdit /></PrivateRoute>} />
            <Route path="/subcategory/view/:_id" element={<PrivateRoute><SubCategoryView /></PrivateRoute>} />

            <Route path="/usercycle" element={<PrivateRoute><UserCycles /></PrivateRoute>} />
            <Route path="/healthnprofile" element={<PrivateRoute><HealthNprofiles /></PrivateRoute>} />
            <Route path="/graphreport" element={<PrivateRoute><GraphReports /></PrivateRoute>} />

            <Route path="/plan" element={<PrivateRoute><Plan /></PrivateRoute>} />
            <Route path="/plan/add" element={<PrivateRoute><PlanAdd /></PrivateRoute>} />
            <Route path="/plan/edit/:_id" element={<PrivateRoute><PlanEdit /></PrivateRoute>} />
            <Route path="/plan/view/:_id" element={<PrivateRoute><PlanView /></PrivateRoute>} />

            <Route path="/usersubscription" element={<UserSubscription />} />

            <Route path="/questions" element={<PrivateRoute><Questions /></PrivateRoute>} />
            <Route path="/questionsadd" element={<PrivateRoute><QuestionsAdd /></PrivateRoute>} />
            <Route path="/question/edit/:_id" element={<PrivateRoute><QuestionsEdit /></PrivateRoute>} />
            <Route path="/question/view/:_id" element={<PrivateRoute><QuestionsView /></PrivateRoute>} />

            {/* <Route path="/" element={<Login />} /> */}
            <Route path="/forgot/password/:_id/:token" element={<Forgot/>} />
            <Route path="*" element={<PageNotFound/>} />
          </Routes>
        </BrowserRouter>
        <ToastContainer position='bottom-right' autoClose={500} /> 
      </AuthContext.Provider>
    </>
  )
}

export default App;
