import React, { useContext, useEffect, useState } from 'react'
import { Button, Container, Card, Form } from 'react-bootstrap';
import base64 from 'base-64'
import { useNavigate } from 'react-router-dom';
import { API, AuthContext } from '../App';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie'

const Login = () => {
  const navigate = useNavigate()
  const { setToken } = useContext(AuthContext);

  const loginData = async (event) => {
    event.preventDefault()
    if (inpData.email === "") {
      toast.error("please enter email id")
    } else if (inpData.password === "") {
      toast.error("please enter password")
    } else {
      const userData = {
        email: inpData.email,
        password: inpData.password
      }
      const response = await API.post("/admin/login", userData)
      if (response.data.status === true) {
        const emailEnc = base64.encode(inpData.email)
        const passEnc = base64.encode(inpData.password)
        if (inpData.remember === true) {
          Cookies.set('emjwt', emailEnc, { expires: 1 })
          Cookies.set('psjwt', passEnc, { expires: 1 })
        } else {
          Cookies.remove('emjwt')
          Cookies.remove('psjwt')

        }
        toast.success('Login Succesfull')
        Cookies.set('ptjwt', response.data.data.token, { expires: 1 })
        setToken(response?.data?.data?.token)

      } else {
        setToken('')
        toast.error(response.data.response_message, { appearance: "error" })
      }
      navigate("/")
    }
  }

  const [inpData, setInpData] = useState({
    email: (Cookies.get('emjwt') && Cookies.get('psjwt')) ? base64.decode(Cookies.get('emjwt')) : "",
    password: (Cookies.get('emjwt') && Cookies.get('psjwt')) ? base64.decode(Cookies.get('psjwt')) : "",
    remember: (Cookies.get('emjwt') && Cookies.get('psjwt')) ? true : false
  })

  const getValue = (e) => {
    setInpData({ ...inpData, [e.target.name]: e.target.value })
  }

  const Remember = (e) => {
    setInpData({ email: inpData.email, password: inpData.password, remember: e.target.checked })
  }

  useEffect(() => {
  }, [])

  // designer code
  const [eye, seteye] = useState(true);
  const [password, setpassword] = useState("password");
  const [type, settype] = useState(false);

  const Eye = () => {
    if (password === "password") {
      setpassword("text");
      seteye(false);
      settype(true);
    }
    else {
      setpassword("password");
      seteye(true);
      settype(false);
    }
  }

  return (
    <>
      <Container>
        <div className="auth">
          <div className="auth-box">
            <Card>
              <Card.Header className="pb-0">
                <div className='auth-logo'>
                  <img src="../logo/app-logo.svg" alt="Vasundhara Infotech" className='logo-mini' />
                </div>
              </Card.Header>
              <Card.Body>
                <Form method='post'>
                  <h1 className='auth-title'>Sign in</h1>
                  <h2 className='auth-subtitle'>Period Tracker</h2>
                  <Form.Group className="mb-3">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" name='email' value={inpData.email} onChange={(e) => { getValue(e) }} className="my-2" placeholder="Enter Your Email" />
                  </Form.Group>
                  <Form.Group className="mb-4 input-prefix">
                    <Form.Label>Password</Form.Label>
                    <Form.Control type={password} name='password' value={inpData.password} onChange={(e) => { getValue(e) }} className="my-2" placeholder="Enter Password" />
                    <i onClick={Eye} className={`bx ${eye ? "bx-hide" : "bx-show"}`}></i>
                  </Form.Group>
                  <Form.Group className="mb-4" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label="Remember Me" checked={inpData.remember} onChange={(e) => { Remember(e) }} />
                  </Form.Group>
                  <Button variant="primary" type="submit" className="w-100 " onClick={loginData}>Sign In</Button>
                </Form>
              </Card.Body>
            </Card>
          </div>
        </div>
      </Container>
    </>
  )
}

export default Login