import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row, Breadcrumb } from "react-bootstrap";
import { Link, useNavigate,useParams } from "react-router-dom";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { toast } from 'react-toastify';
import { API } from '../../App';
import Layout from '../../layout/Layout';

const QuestionsEdit = () => {

    //code
    const [validate,setValidate] = useState(false)
    const {_id} = useParams()
    const navigate = useNavigate()
    const [delId,setDelId] = useState([])
    const [user,setUser] = useState({
        question:"",
        status:1,
        id:""
    })
    const [option,setOption] = useState([])
    const getData = async(id) => {
        const token = Cookies.get('ptjwt')
        const questionData = await API.post(`/admin/show/question/${id}`,{} , {headers : {"Authorization" : `Bearer ${token}`}})
        if(questionData.data.status===false){
            if (questionData.data.response_code === 429) {
                navigate("/")
            } else {
                toast.error('something went wrong')
            }
        }else{
            setUser({
                question:questionData.data.data.question,
                status:questionData.data.data.status,
                id:questionData.data.data._id
            })
            setOption(questionData.data.data.options)
        }
    }
    //option
    const addOption = (tag1, name) => {
        const optionTag = {
            option:""
        }
        setOption([...option, optionTag])
    }

    const removeOption = (index,id) => {
        if(id!==undefined){
            setDelId([...delId,id])
        }
        let data = [...option]
        data.splice(index, 1)
        setOption(data)
    }

    const optionChange = (e, index) => {
        let data = [...option];
        data[index]['option'] = e.target.value
        setOption(data)
    }

    const saveData = async () => {
        if (user.question==="") {
            setValidate(true)
        }else{
            let optCounter = 0
            option.map((optVal) => {
                if (optVal.option==="") {
                    optCounter++
                }
            })
            if (optCounter === 0) {
                const Form = {
                    question:user.question,
                    status:user.status,
                    option:option,
                    del:delId
                }
                const token = Cookies.get('ptjwt')
                const response = await API.post(`admin/edit/question/${_id}`, Form, { headers: { "Authorization": `Bearer ${token}` } })
                if (response.data.status === true) {
                  toast.success("Question added succesfully")
                  navigate("/Questions")
                } else {
                  if (response.data.response_code === 429) {
                    navigate("/")
                  } else {
                    toast.error('something went wrongg')
                  }
                }
            }else{
                setValidate(true)
            }
        }
    }

    useEffect(()=>{
        getData(_id)
    },[])
    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3>Edit Questions</h3>
                <Breadcrumb className="d-none d-lg-block">
                    <Breadcrumb.Item href='/'>
                        <i className='bx bx-home-alt me-2 fs-5' ></i> Home
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href='/Questions'>
                        Questions
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Edit Questions</Breadcrumb.Item>
                </Breadcrumb>
            </div>

            <div className="page-content">
                <Form noValidate validated={validate}>
                    <Row>
                        <Col xs={12}>
                            <Card className="mb-4">
                                <Card.Body>
                                    <Row className='justify-content-center'>
                                        <Col lg={9} sm={12}>
                                            <Row>
                                                <Col md='9' xs={12}>
                                                    <Form.Label htmlFor="name" >Question</Form.Label>
                                                    <Form.Control type="text" className="my-2" name='question' value={user.question} onChange={(e) => { setUser({...user,['question']:e.target.value}) }} placeholder='Enter Question' required/>
                                                </Col>
                                                <Col lg={2} sm="12">
                                                    <Form.Label htmlFor="media_type" className='d-block mb-2'>Status</Form.Label>
                                                    <BootstrapSwitchButton onlabel='Enable' offlabel='Disable' checked={user.status===1?true:false} onChange={(e)=>{setUser({...user,['status']:e===true?1:0})}} size="sm" width={100} />
                                                </Col>
                                                <Col xs={12}>
                                                    {
                                                            option.map((optVal,ind)=>{
                                                                return(
                                                                    <Row key={ind}>
                                                                        <Col md={7}>
                                                                            <Form.Control type="text" className="my-2" name='Name' value={optVal.option} onChange={(e) => { optionChange(e, ind) }} placeholder='Enter Option' required/>
                                                                        </Col>
                                                                        {
                                                                            ind===1?
                                                                            <Col md={1} className="text-center">
                                                                                <Button variant='primary' className='btn-icon my-2' onClick={addOption}><i className='bx bx-plus'></i></Button>
                                                                            </Col>:
                                                                            (
                                                                                ind>1?
                                                                                <Col md={1} className="text-center">
                                                                                    <Button variant='danger' className='btn-icon my-2' onClick={() => { removeOption(ind,optVal?._id) }}><i className='bx bx-minus'></i></Button>
                                                                                </Col>:""
                                                                            )
                                                                        }
                                                                    </Row>
                                                                )
                                                            })
                                                        }
                                                    {/* <Row>
                                                        <Col md={11}>
                                                            <Form.Control type="text" className="my-2" name='Name' placeholder='Enter Answer' required/>
                                                        </Col>
                                                        <Col md={11}>
                                                            <Form.Control type="text" className="my-2" name='Name' placeholder='Enter Answer' required/>
                                                        </Col>
                                                        <Col md={1} className="text-center">
                                                            <Button variant='primary' className='btn-icon my-2'><i className='bx bx-plus'></i></Button>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md={11}>
                                                            <Form.Control type="text" className="my-2" name='Name' placeholder='Enter Answer' required/>
                                                        </Col>
                                                        <Col md={1} className="text-center">
                                                            <Button variant='danger' className='btn-icon my-2'><i className='bx bx-minus'></i></Button>
                                                        </Col>
                                                    </Row> */}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card.Body>
                                <Card.Footer className="text-end">
                                    <Button variant="primary" className="me-3" onClick={saveData}>Save</Button>
                                    <Link to="/Questions">
                                        <Button variant="secondary">Cancle</Button>
                                    </Link>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Layout>
    )
}

export default QuestionsEdit