import React, { useEffect, useState } from 'react'
import { Row, Col, Table, Card, Button, Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Layout from '../../layout/Layout';
import Pagination from 'rc-pagination'
import Cookies from 'js-cookie';
import { API } from '../../App';
import { toast } from 'react-toastify';
import $ from 'jquery'
import Swal from 'sweetalert2'

const Plan = () => {

    //code
    const [sort, setSort] = useState({
        _id:1
    })
    const [iconcoror, Seticoncoror] = useState("id_up")
    const [oid,setOid] = useState([])
    const [dataLength, setDataLength] = useState(1)
    const [loading,setLoading] = useState(true)
    const [data, setData] = useState([])
    const [sData, setSData] = useState([])
    const navigate = useNavigate()
    const getData = async (sorting) => {
        const token = Cookies.get('ptjwt')
        const response = await API.post("/admin/plan",sorting,{ headers : { "Authorization" : `Bearer ${token}`}})
        if (response.data.status === false) {
            if (response.data.response_code === 429) {
                navigate("/")
            } else {
                setData([])
                setSData([])
                setOid([])
                setDataLength(0)
                setLoading(false)
            }
        } else {
            setData(response.data.data.data)
            setSData(response.data.data.data)
            setOid(response.data.data.id)
            setDataLength(1)
            setLoading(false)
        }
    }

    // Delete Code

    const deleteUser = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger me-2'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                const token = Cookies.get('ptjwt')
                const delCategory = await API.post(`/admin/delete/plan/${id}`, {}, { headers: { "Authorization": `Bearer ${token}` } })
                console.log(delCategory);
                if (delCategory.data.status === false) {
                    if (delCategory.data.response_code === 429) {
                        navigate("/")
                    } else {
                        swalWithBootstrapButtons.fire(
                            'Cancelled',
                            `${delCategory.data.response_message}`,
                            'error'
                        )
                    }
                } else {
                    getData(sort)
                    setCurrent(1)
                }
    
            }
        })
    }

    // Search Code
    const requestSearch = (searchedVal) => {
        const filteredRows = data.filter((row) => {
            return row.name.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
    
        });
        if (searchedVal.length < 1) {
            $('#Data_not_found').css({ display: "none" })
            getData(sort)
        }
        else {
            if (filteredRows.length === 0) {
                const filteredRows1 = sData.filter((row) => {
                    return row.name.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
                });
                if (filteredRows1.length === 0) {
                    setData(filteredRows)
                    setDataLength(0)
                }
                else {
                    $('#remove_tr').empty()
                    setData(filteredRows1)
                    setCurrent(1)
                    setDataLength(1)
                }
            }
            else {
                const filteredRows1 = sData.filter((row) => {
                    return row.name.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
                });
                if (filteredRows1.length === 0) {
                    $('#remove_tr').empty()
                    $('#remove_tr').append('<td colSpan="100%" class="p-0"><div class="no-found">No Records Found</div></td>')
                    setData(filteredRows)
    
                }
                else {
                    $('#remove_tr').empty()
                    setData(filteredRows1)
                    setCurrent(1)
                }
                // setData(filteredRows)
                // setCurrent(1)
            }
        }
    };

    // Paggintion Code // 
    const [perPage, setPerPage] = useState(10);
    const [size, setSize] = useState(perPage);
    const [current, setCurrent] = useState(1);

    const wetData = (current, pageSize) => {
        return data.slice((current - 1) * pageSize, current * pageSize);
    };

    const PerPageChange = (value) => {
        console.log(value)
        setSize(value);
        console.log(size)
        const newPerPage = Math.ceil(data.length / value);
        if (current > newPerPage) {
            setCurrent(newPerPage);
        }
    }

    const PaginationChange = (page, pageSize) => {
        setCurrent(page);
        setSize(pageSize)
    }


    const PrevNextArrow = (current, type, originalElement) => {
        if (type === 'prev') {
            return <button className='paggination-btn'>Previous</button>;
        }
        if (type === 'next') {
            return <button className='paggination-btn'>Next</button>;
        }
        return originalElement;
    }

    const sorting = (col, type = "string", order, e) => {
        Seticoncoror(e.target.id)
        if (order === "ASC") {
          const sorted = [...data].sort((a, b) =>
            a[col] > b[col] ? 1 : -1
          );
          if (iconcoror !== e.target.id) {
            setData(sorted)
          }
        }
        if (order === "DSC") {
          const sorted = [...data].sort((a, b) =>
            a[col] < b[col] ? 1 : -1
          );
          if (iconcoror !== e.target.id) {
            setData(sorted)
          }
        }
    }

    useEffect(() => {
        getData(sort)
    }, [])
    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3 className="my-1">Plans</h3>
                <Row className="justify-content-end align-items-center">
                    <Col md="auto" xs={12}>
                        <Form.Control type="text" name="" id="" placeholder="Search Plan Name" onChange={(e) => requestSearch(e.target.value)} className="my-1" />
                    </Col>
                    <Col md="auto" xs={6}>
                        <Form.Select onChange={e => { setSize(Number(e.target.value)); setCurrent(1) }} className="my-1">
                        {
                            [10, 25, 50, 100].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                            ))
                        }
                        </Form.Select>
                    </Col>
                    <Col md="auto" xs={6}>
                        <Link to="/plan/add">
                            <Button variant="primary" className='my-1' value="create">Add New</Button>
                        </Link>
                    </Col>
                </Row>
            </div>
            <div className="page-content">
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Body>
                                <Table bordered responsive>
                                    <thead>
                                        <tr>
                                            <th width="5%">
                                            <div className='table-sort-filter justify-content-center'>
                                                No.
                                                {/* <span className='table-sort'>
                                                    <div className={`sort-down ${iconcoror === "id_down" ? "active" : ""}`} id="id_down" onClick={(e) => { sorting('_id', "", "DSC", e) }} ></div>
                                                    <div className={`sort-up ${iconcoror === "id_up" ? "active" : ""}`} id="id_up" onClick={(e) => { sorting('_id', "", "ASC", e) }} ></div>
                                                </span> */}
                                            </div>    
                                            </th>
                                            <th width="70%">
                                            <div className='table-sort-filter'>
                                                Name
                                                <span className='table-sort'>
                                                    <div className={`sort-down ${iconcoror === "name_down" ? "active" : ""}`} id="name_down" onClick={(e) => { sorting('name', "", "DSC", e) }} ></div>
                                                    <div className={`sort-up ${iconcoror === "name_up" ? "active" : ""}`} id="name_up" onClick={(e) => { sorting('name', "", "ASC", e) }} ></div>
                                                </span>
                                            </div>
                                            </th>
                                            <th width="15%">
                                            <div className='table-sort-filter'>
                                                Days
                                                <span className='table-sort'>
                                                    <div className={`sort-down ${iconcoror === "days_down" ? "active" : ""}`} id="days_down" onClick={(e) => { sorting('days', "", "DSC", e) }} ></div>
                                                    <div className={`sort-up ${iconcoror === "days_up" ? "active" : ""}`} id="days_up" onClick={(e) => { sorting('days', "", "ASC", e) }} ></div>
                                                </span>
                                            </div>    
                                            </th>
                                            <th width="10%" className='text-center'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            wetData(current, size).map((dataVal,ind)=>{
                                                return(
                                                    <tr key={ind}>
                                                        <td className='text-center'>{(current === 1) ? ind + 1 : current * size + ind + 1 - size}</td>
                                                        {/* <td className='text-center'>{oid.indexOf(dataVal._id)+1}</td> */}
                                                        <td>{dataVal?.name}</td>
                                                        <td>{dataVal?.days}</td>
                                                        <td className='text-center'>
                                                            <Link to={`/plan/view/${dataVal._id}`}>
                                                                <Button variant="outline-warning" size="sm" className="me-2 btn-icon"><i className='bx bx-show'></i></Button>
                                                            </Link>
                                                            <Button variant="outline-danger" size="sm" className="btn-icon" onClick={() => deleteUser(dataVal._id)}><i className='bx bx-trash-alt' ></i></Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        {
                                            dataLength === 0 ? <tr>
                                                <td colSpan="100%" className="p-0">
                                                    <div className='no-found'>
                                                        <img src="../../not-found/plan.svg" />
                                                        <p>Plans Not Found</p>
                                                    </div>
                                                </td>
                                            </tr> : ""
                                        }
                                    </tbody>
                                </Table>
                                <div className="pagination-custom">
                                    {   data.length > size ?
                                        <Pagination showTitle={false}
                                        className="pagination-data"
                                        onChange={PaginationChange}
                                        total={data.length}
                                        current={current}
                                        pageSize={size}
                                        showSizeChanger={false}
                                        itemRender={PrevNextArrow}
                                        onShowSizeChange={PerPageChange}
                                        />:""
                                    }
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Layout>
    )
}

export default Plan