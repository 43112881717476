import React from 'react';
import { Link, NavLink } from 'react-router-dom'
import Logo from '../Component/Logo';
import LogoMini from '../Component/Logo-mini';

const Sidebar = ({ sidebar }) => {
  return (
    <>
      <div className={sidebar ? "sidebar-wrapper active" : "sidebar-wrapper"}>
        <div className="sidebar-header">
          <div className="d-flex justify-content-between">
            <div className='sidebar-logo'>
              <Link to="/">
                <Logo />
                <LogoMini />
              </Link>
            </div>
          </div>
        </div>
        <div className="sidebar-menu">
          <ul className="menu">
            <li className="sidebar-item">
              <NavLink to="/" className='sidebar-link'>
                <i className='bx bxs-home'></i>
                <span>Dashboard</span>
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink to="/category" className='sidebar-link'>
                <i className='bx bxs-category-alt' ></i>
                <span>Categories</span>
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink to="/subcategory" className='sidebar-link'>
                <i className='bx bxs-category'></i>
                <span>Sub Categories</span>
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink to="/usercycle" className='sidebar-link'>
                <i className='bx bx-recycle'></i>
                <span>User Cycles</span>
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink to="/healthnprofile" className='sidebar-link'>
                <i className='bx bx-health' ></i>
                <span>Health And Profiles</span>
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink to="/graphreport" className='sidebar-link'>
                <i className='bx bxs-chart' ></i>
                <span>Graph Reports</span>
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink to="/questions" className='sidebar-link'>
                <i className='bx bxs-book'></i>
                <span>Questions</span>
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink to="/plan" className='sidebar-link'>
                <i className='bx bxs-calendar-check'></i>
                <span>Plans</span>
              </NavLink>
            </li>
            <li className="sidebar-item">
              <NavLink to="/usersubscription" className='sidebar-link'>
                <i className='bx bxs-user' ></i>
                <span>User Subscriptions</span>
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default Sidebar