import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row, Breadcrumb, InputGroup } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Layout from '../../layout/Layout';
import { toast } from 'react-toastify';
import { API } from '../../App';
import Cookies from 'js-cookie';

const SubCategoryEdit = () => {

    const navigate = useNavigate()
    const [validate,setValidate] = useState(false)
    const [data,setData] = useState([])
    const {_id} = useParams()
    const [user, setUser] = useState({
        Category:"",
        Name: "",
        Status: "",
        Icon:"",
        Header:"",
        craetedAt:"",
        updatedAt:"",
        id: ""
    })

    const getData = async () => {
        const token = Cookies.get('ptjwt')
        const subCategoryData = await API.post(`/admin/view/sub_category/${_id}`,{},{ headers: { "Authorization": `Bearer ${token}` } } )
        if(subCategoryData.data.status===false){
            toast.error("something went wrong")
        }else{
            setUser({
                Category:subCategoryData.data.data.category_id._id,
                Name:subCategoryData.data.data.name,
                Status:subCategoryData.data.data.status,
                Icon:subCategoryData.data.data.icon,
                Header:subCategoryData.data.data.header?subCategoryData.data.data.header:"",
                craetedAt:subCategoryData.data.data.createdAt,
                updatedAt:subCategoryData.data.data.updatedAt,
                id:subCategoryData.data.data._id
            })
        }
        const category = await API.post("/admin/category", {}, { headers: { "Authorization": `Bearer ${token}` } })
        if(category.data.status === false) {
            if(category.data.response_code===429){
                navigate("/")
            }else{
                toast.error("Something went wrong in category")
            }
        }else{
            setData(category.data.data.data)
        }
    }

    const getVal = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value })
    }

    const [selectedImage, setSelectedImage] = useState(null);

    const onImags = (event) => {
        setSelectedImage(event.target.files[0]);
    }

    const saveData = async () => {
        if (user.Category==="" || user.Name==="" || user.Header==="") {
            setValidate(true)
        } else {
            if(user.Name.match(/^[A-Za-z& ]+$/)){
                const Form = new FormData
                Form.append("category", user.Category)
                Form.append("name", user.Name)
                Form.append("header", user.Header)
                Form.append("status", user.Status)
                Form.append('image', selectedImage === null ? user.Image : selectedImage)
                const token = Cookies.get('ptjwt')
                const response = await API.post(`/admin/edit/sub_category/${_id}`, Form, { headers: { "Authorization": `Bearer ${token}` } })
                if (response.data.status === true) {
                    toast.success("Category updated")
                    navigate(`/subcategory/view/${_id}`)
                } else {
                    if (response.data.response_code === 429) {
                        navigate("/")
                    } else {
                        toast.error('something went wrong')
                    }
                }
            }else{
                toast.error('please enter valid SubCategory name')
            }
        }
    }

    useEffect(() => {
        getData()
    }, [])
    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3>Edit Sub Category</h3>
                <Breadcrumb className="d-none d-lg-block">
                    <Breadcrumb.Item >
                        <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item >
                        <Link to="/subcategory">Sub Category</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Edit Sub Category</Breadcrumb.Item>
                </Breadcrumb>
            </div>

            <div className="page-content">
            <Form noValidate validated={validate}>
                <Row>
                    <Col xs={12}>
                        <Card className="mb-4">
                            <Card.Body>
                                <Row>
                                    <Col sm={3}>
                                        <Form.Label htmlFor="mcat" >Category</Form.Label>
                                        <Form.Select id='mcat' className='my-2' name="Category" onChange={(e) => { getVal(e) }} required>
                                            <option value="">Select Category</option>
                                            {
                                                data.map((dataVal,ind) => {
                                                    return (
                                                        <option selected={dataVal._id === user.Category ? true : false} value={dataVal._id} key={ind}>{dataVal.name}</option>
                                                    )
                                                })
                                            }
                                        </Form.Select>
                                    </Col>
                                    <Col lg={3} sm={12}>
                                        <Form.Label htmlFor="cate" >Name</Form.Label>
                                        <Form.Control type="text" id='cate' className="my-2" value={user.Name} name="Name" onChange={(e) => { getVal(e) }} placeholder='Enter Name' required/>
                                    </Col>
                                    <Col lg={3} sm={12}>
                                        <Form.Label htmlFor="icon" >Icon</Form.Label>
                                        <InputGroup className="my-2">
                                            <Form.Control type="file" id='icon'  onChange={(e) => { { onImags(e) } }} />
                                            <img src={user.Icon} className='hv-40' alt="Image" />
                                        </InputGroup>
                                    </Col>
                                    <Col lg={3} sm={12}>
                                        <Form.Label htmlFor="cate" >Header</Form.Label>
                                        <Form.Control type="text" id='cate' className="my-2" value={user.Header} name='Header' onChange={(e) => { getVal(e) }} placeholder='Enter Header' required/>
                                    </Col>
                                    <Col lg={3} sm="auto">
                                        <Form.Label htmlFor="status" className='d-block mb-2'>Status</Form.Label>
                                        <BootstrapSwitchButton id="status" checked={user.Status === 1 ? true : false} onChange={(checked) => { if (checked == true) { setUser({ ...user, ['Status']: 1 }) } else { setUser({ ...user, ['Status']: 0 }) } }} onlabel='Enable' offlabel='Disabled' size="sm" width={100} />
                                    </Col>
                                </Row>
                            </Card.Body>
                            <Card.Footer className="text-end">
                                <Button variant="primary" className="me-3" onClick={saveData}>Save</Button>
                                <Link to={`/subcategory/view/${_id}`}>
                                    <Button variant="secondary">Cancle</Button>
                                </Link>
                            </Card.Footer>
                        </Card>
                    </Col>
                </Row>
            </Form>
            </div>
        </Layout>
    )
}

export default SubCategoryEdit