import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { API } from '../../App';
import Layout from '../../layout/Layout';
import { toast } from 'react-toastify';

const QuestionsView = () => {

    //code
    const {_id} = useParams()
    const navigate = useNavigate()
    const [category,SetCategory] = useState("")
    const [data,setData] = useState([])
    const [user,setUser] = useState({
        question:"",
        option:[],
        status:1,
        id:""
    })
    const getData = async(id) => {
        const token = Cookies.get('ptjwt')
        const questionData = await API.post(`/admin/show/question/${id}`,{} , {headers : {"Authorization" : `Bearer ${token}`}})
        if(questionData.data.status===false){
            if (questionData.data.response_code === 429) {
                navigate("/")
            } else {
                toast.error('something went wrong')
            }
        }else{
            setUser({
                question:questionData.data.data.question,
                option:questionData.data.data.options,
                status:questionData.data.data.status,
                id:questionData.data.data._id
            })
            SetCategory(questionData.data.data._id)
        }
        const question = await API.post("/admin/get/question" , {} ,{headers : {"Authorization" : `Bearer ${token}`}})
        if (question.data.status === false) {
            if (question.data.response_code === 429) {
                navigate("/")
            } else {
                toast.error("Something Went Wrong")
            }
        } else {
            setData(question.data.data.data)
        }
    }

    const getCategory = (e) => {
        SetCategory(e.target.value)
        getData(e.target.value)
    }

    useEffect(()=>{
        getData(_id)
    },[])
    return (
        <Layout sidebar={true}>
            <div div className="page-heading">
                <h3><Link to="/Questions" className='btn btn-primary btn-icon me-3'><i class='bx bxs-left-arrow-alt'></i></Link><span>View Questions</span></h3>
                <Row className="align-items-center">
                    <Col md="auto" xs={6}>
                        <Form.Select className="my-1" onChange={(e)=>{getCategory(e)}}>
                        {
                            data.map((dataVal,ind) => {
                                return (
                                    <option selected={dataVal?._id === user.id ? true : false} value={dataVal._id} key={ind}>{dataVal.question}</option>
                                )
                            })
                        }
                        </Form.Select>
                    </Col>
                    <Col md="auto" xs={6}>
                        <Link to={`/question/edit/${category}`}>
                            <Button variant="primary">Edit</Button>
                        </Link>
                    </Col>
                </Row>
            </div>
            <div className='page-content'>
                <Card>
                    <Card.Body>
                        <Row>
                            <Col md={12}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Questions</p>
                                    <span>{user.question}</span>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Answer</p>
                                    <span>
                                        <ul className='mb-0'>
                                        {
                                            user?.option?.map((dataVal,ind)=>{
                                                return(
                                                    <li>{dataVal?.option}</li>
                                                )
                                            })
                                        }
                                        </ul>
                                    </span>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Status</p>  <span>{user.status===1?"Enable":"Disable"}</span>
                                </div>
                            </Col>
                            {/* <Col md={3}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Created At</p>  <span>2023-01-06T08:12:23.639Z</span>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Updated At</p>  <span>2023-01-18T03:49:50.885Z</span>
                                </div>
                            </Col> */}
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        </Layout>
    )
}

export default QuestionsView