import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row, Breadcrumb } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { API } from '../../App';
import Layout from '../../layout/Layout';
const PlanAdd = () => {

    //code
    const [validate,setValidate] = useState(false)
    const navigate = useNavigate()
    const [inpData, setInpData] = useState({
        Name: "",
        Days:"",
    })

    const saveData = async (event) => {
        if (inpData.Name === "") {
            setValidate(true)
        }else {
        if(inpData.Name.match(/^[A-Za-z& ]+$/)){
            const Form = new FormData()
            Form.append('name', inpData.Name)
            Form.append('days', inpData.Days)
            const token = Cookies.get('ptjwt')
            const response = await API.post("/admin/add/plan", Form, { headers: { "Authorization": `Bearer ${token}` } })
            if (response.data.status === true) {
            toast.success("Plan created")
            navigate("/plan")
            } else {
            if (response.data.response_code == 429) {
                navigate("/")
            } else {
                toast.error('something went wrong')
            }
            }
        }else{
            toast.error('please enter valid Plan name')
        }
        }
    }

    useEffect(() => {
    }, [])
    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3>Create Plan</h3>
                <Breadcrumb className="d-none d-lg-block">
                    <Breadcrumb.Item >
                        <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item >
                        <Link to="/plan">Plan</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Create Plan</Breadcrumb.Item>
                </Breadcrumb>
            </div>

            <div className="page-content">
                <Form noValidate validated={validate}>
                    <Row>
                        <Col xs={12}>
                            <Card className="mb-4">
                                <Card.Body>
                                    <Row>
                                        <Col lg={3} sm={12}>
                                            <Form.Label htmlFor="name" >Name</Form.Label>
                                            <Form.Control type="text" id='name' className="my-2" name='Name' value={inpData.Name} onChange={(e)=>{setInpData({...inpData,['Name']:e.target.value})}} placeholder='Enter Name' required/>
                                        </Col>
                                        <Col lg={3} sm={12}>
                                            <Form.Label htmlFor="days" >Days</Form.Label>
                                            <Form.Control type="number" id='days' className="my-2" name='Name' value={inpData.Days} onChange={(e)=>{setInpData({...inpData,['Days']:e.target.value})}} placeholder='Enter Header' required/>
                                        </Col>
                                    </Row>
                                </Card.Body>
                                <Card.Footer className="text-end">
                                    <Button variant="primary" className="me-3" onClick={saveData}>Save</Button>
                                    <Link to="/plan">
                                        <Button variant="secondary">Cancle</Button>
                                    </Link>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Layout>
    )
}

export default PlanAdd