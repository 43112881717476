import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import { API } from '../../App';
import Layout from '../../layout/Layout';
import Cookies from 'js-cookie'

const CategoryView = () => {

    //code
    const {_id} = useParams()
    const navigate = useNavigate()
    const [category,SetCategory] = useState("")
    const [data,setData] = useState([])
    const [user,setUser] = useState({
        name:"",
        status:"",
        isGraph:"",
        craetedAt:"",
        updatedAt:"",
        id:""
    })
    const getData = async(id) => {
        const token = Cookies.get('ptjwt')
        const categoryData = await API.post(`/admin/view/category/${id}`,{} , {headers : {"Authorization" : `Bearer ${token}`}})
        if(categoryData.data.status===false){
            if (categoryData.data.response_code === 429) {
                navigate("/")
            } else {
                toast.error('something went wrong')
            }
        }else{
            setUser({
                name:categoryData.data.data.name,
                status:categoryData.data.data.status,
                isGraph:categoryData.data.data.is_graph,
                craetedAt:categoryData.data.data.createdAt,
                updatedAt:categoryData.data.data.updatedAt,
                id:categoryData.data.data._id
            })
            SetCategory(categoryData.data.data._id)
        }
        const category = await API.post("/admin/category" , {} ,{headers : {"Authorization" : `Bearer ${token}`}})
        if (category.data.status === false) {
            if (category.data.response_code === 429) {
                navigate("/")
            } else {
                toast.error("Something Went Wrong")
            }
        } else {
            setData(category.data.data.data)
        }
    }

    const getCategory = (e) => {
        SetCategory(e.target.value)
        getData(e.target.value)
    }

    useEffect(()=>{
        getData(_id)
      },[])
    return (
        <>
            <Layout sidebar={true}>
                <div div className="page-heading">
                    <h3><Link to="/category" className='btn btn-primary btn-icon me-3'><i class='bx bxs-left-arrow-alt'></i></Link><span>View Category</span></h3>
                    <Row className="align-items-center">
                        <Col md="auto" xs={6}>
                            <Form.Select className="my-1" onChange={(e)=>{getCategory(e)}}>
                                {
                                    data.map((dataVal,ind) => {
                                        return (
                                            <option selected={dataVal?._id === user.id ? true : false} value={dataVal._id} key={ind}>{dataVal.name}</option>
                                        )
                                    })
                                }
                            </Form.Select>
                        </Col>
                        <Col md="auto" xs={6}>
                            <Link to={`/category/edit/${category}`}>
                                <Button variant="primary" value="edit">Edit</Button>
                            </Link>
                        </Col>
                    </Row>
                </div>
                <div className='page-content'>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Name</p>  <span>{user?.name}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Status</p>  <span>{user?.status===1?"Enable":"Disable"}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Is Graph</p>  <span>{user?.isGraph===1?"Enable":"Disable"}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Created At</p>  <span>{user?.craetedAt}</span>
                                    </div>
                                </Col>
                                <Col md={3}>
                                    <div className='mb-4'>
                                        <p className='mb-0 fw-bold'>Updated At</p>  <span>{user?.updatedAt}</span>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </div>
            </Layout>
        </>
    )
}

export default CategoryView