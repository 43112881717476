import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row, Breadcrumb } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import { API } from '../../App';
import Layout from '../../layout/Layout';

const PlanEdit = () => {

    const navigate = useNavigate()
    const [validate,setValidate] = useState(false)
    const {_id} = useParams()
    const [user, setUser] = useState({
        Name: "",
        Days: "",
        craetedAt:"",
        updatedAt:"",
        id: ""
    })

    const getData = async () => {
        const token = Cookies.get('ptjwt')
        const categoryData = await API.post(`/admin/view/plan/${_id}`,{},{ headers: { "Authorization": `Bearer ${token}` } } )
        if(categoryData.data.status===false){
            toast.error("something went wrong")
        }else{
            setUser({
                Name:categoryData.data.data.name,
                Days:categoryData.data.data.days,
                craetedAt:categoryData.data.data.createdAt,
                updatedAt:categoryData.data.data.updatedAt,
                id:categoryData.data.data._id
            })
        }
    }

    const saveData = async () => {
        if (user.Name==="" || user.Days==="") {
            setValidate(true)
        } else {
        if(user.Name.match(/^[A-Za-z& ]+$/)){
            const Form = new FormData
            Form.append("name", user.Name)
            Form.append("days", user.Days)
            const token = Cookies.get('ptjwt')
            const response = await API.post(`/admin/edit/plan/${_id}`, Form, { headers: { "Authorization": `Bearer ${token}` } })
            if (response.data.status === true) {
            toast.success("Plan updated")
            navigate(`/plan/view/${_id}`)
            } else {
            if (response.data.response_code === 429) {
                navigate("/")
            } else {
                toast.error('something went wrong')
            }
            }
        }else{
            toast.error('please enter valid Category name')
        }
        }
    }

    useEffect(() => {
        getData()
    }, [])
    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3>Edit Plan</h3>
                <Breadcrumb className="d-none d-lg-block">
                    <Breadcrumb.Item >
                        <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item >
                        <Link to="/plan">Plan</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Edit Plan</Breadcrumb.Item>
                </Breadcrumb>
            </div>

            <div className="page-content">
                <Form noValidate validated={validate}>
                    <Row>
                        <Col xs={12}>
                            <Card className="mb-4">
                                <Card.Body>
                                    <Row>
                                        <Col lg={3} sm={12}>
                                            <Form.Label htmlFor="name" >Name</Form.Label>
                                            <Form.Control type="text" id='name' className="my-2" name='Name' value={user.Name} onChange={(e)=>{setUser({...user,['Name']:e.target.value})}} placeholder='Enter Name' required/>
                                        </Col>
                                        <Col lg={3} sm={12}>
                                            <Form.Label htmlFor="days" >Days</Form.Label>
                                            <Form.Control type="number" id='days' className="my-2" name='Days' value={user.Days} onChange={(e)=>{setUser({...user,['Days']:e.target.value})}} placeholder='Enter Days' required/>
                                        </Col>
                                    </Row>
                                </Card.Body>
                                <Card.Footer className="text-end">
                                    <Button variant="primary" className="me-3" onClick={saveData}>Save</Button>
                                    <Link to={`/plan/view/${_id}`}>
                                        <Button variant="secondary">Cancle</Button>
                                    </Link>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Layout>
    )
}

export default PlanEdit