import React, { useEffect, useState } from 'react'
import { Row, Col, Table, Card, Form } from 'react-bootstrap';
import Layout from '../../layout/Layout';
import Pagination from 'rc-pagination'
import Cookies from 'js-cookie';
import { API } from '../../App';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery'
import { toast } from 'react-toastify';

const GraphReports = () => {

    //code
    const [dataLength, setDataLength] = useState(1)
    const [loading,setLoading] = useState(true)
    const [category,setCategory] = useState([])
    const [search,setSearch] = useState("")
    const [data, setData] = useState([])
    const [sData, setSData] = useState([])
    const navigate = useNavigate()
    const getData = async (cat) => {
        const token = Cookies.get('ptjwt')
        const response = await API.post("/admin/graph_report",cat===undefined?{}:{cat:cat},{ headers : { "Authorization" : `Bearer ${token}`}})
        if (response.data.status === false) {
            if (response.data.response_code === 429) {
                navigate("/")
            } else {
                setData([])
                setSData([])
                setDataLength(0)
                setLoading(false)
            }
        } else {
            setData(response.data.data)
            setSData(response.data.data)
            setDataLength(1)
            setLoading(false)
        }
    }

    const getCategoryFilt = async() => {
        const token = Cookies.get('ptjwt')
        const catRes = await API.post("/admin/graph/category", {}, { headers: { "Authorization": `Bearer ${token}` } })
        if (catRes.data.status === true) {
            setCategory(catRes.data.data)
        }else{
            toast.error("Something went wrong in Category Filter")
        }
    }

     // Paggintion Code // 
    const [perPage, setPerPage] = useState(10);
    const [size, setSize] = useState(perPage);
    const [current, setCurrent] = useState(1);

    const wetData = (current, pageSize) => {
        return data.slice((current - 1) * pageSize, current * pageSize);
    };

    const PerPageChange = (value) => {
        console.log(value)
        setSize(value);
        console.log(size)
        const newPerPage = Math.ceil(data.length / value);
        if (current > newPerPage) {
            setCurrent(newPerPage);
        }
    }

    const PaginationChange = (page, pageSize) => {
        setCurrent(page);
        setSize(pageSize)
    }


    const PrevNextArrow = (current, type, originalElement) => {
        if (type === 'prev') {
            return <button className='paggination-btn'>Previous</button>;
        }
        if (type === 'next') {
            return <button className='paggination-btn'>Next</button>;
        }
        return originalElement;
    }

     //dropdown search code
    const categoryChange = (e) => {
        getData(e.target.value)
        setSearch(e.target.value)
    }

    //serach

    const requestSearch = (searchedVal) => {
        const filteredRows = data.filter((row) => {
            return row.user_id.email.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());

        });
        if (searchedVal.length < 1) {
            $('#Data_not_found').css({ display: "none" })
            getData(search)
        }
        else {
            if (filteredRows.length === 0) {
                const filteredRows1 = sData.filter((row) => {
                    return row.user_id.email.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
                });
                if (filteredRows1.length === 0) {
                    setData(filteredRows)
                    setDataLength(0)
                }
                else {
                    $('#remove_tr').empty()
                    setData(filteredRows1)
                    setCurrent(1)
                    setDataLength(1)
                }
            }
            else {
                const filteredRows1 = sData.filter((row) => {
                    return row.user_id.email.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
                });
                if (filteredRows1.length === 0) {
                    $('#remove_tr').empty()
                    $('#remove_tr').append('<td colSpan="100%" class="p-0"><div class="no-found">No Records Found</div></td>')
                    setData(filteredRows)

                }
                else {
                    $('#remove_tr').empty()
                    setData(filteredRows1)
                    setCurrent(1)
                }
                // setData(filteredRows)
                // setCurrent(1)
            }
        }
    };

    useEffect(() => {
        getData()
        getCategoryFilt()
    }, [])
    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3 className="my-1">Graph Reports</h3>
                <Row className="justify-content-end align-items-center">
                    <Col md="auto" xs={8}>
                        <Form.Control type="text" name="" id="" placeholder="Search User Name" onChange={(e) => requestSearch(e.target.value)} className="my-1" />
                    </Col>
                    <Col md="auto" xs={4}>
                        <Form.Select id='mcat' className='my-1' name='Category' onChange={categoryChange}>
                        <option value="">Select Category</option>
                        {
                            category.map((dataVal,ind) => {
                                return (
                                    <option value={dataVal._id} key={ind}>{dataVal.name}</option>
                                )
                            })
                        }
                        </Form.Select>
                    </Col>
                    <Col md="auto" xs={4}>
                        <Form.Select onChange={e => { setSize(Number(e.target.value)); setCurrent(1) }} className="my-1">
                        {
                            [10, 25, 50, 100].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                            ))
                        }
                        </Form.Select>
                    </Col>
                </Row>
            </div>
            <div className="page-content">
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Body>
                                <Table bordered responsive>
                                    <thead>
                                        <tr>
                                            <th width="70%">User Name</th>
                                            <th width="15%">Graph Category</th>
                                            <th width="15%">Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            wetData(current, size).map((dataVal,ind)=>{
                                                return(
                                                    <tr key={ind}>
                                                        <td>{dataVal?.user_id?.email}</td>
                                                        <td>{dataVal?.graph_cat_id?dataVal.graph_cat_id.name:""}</td>
                                                        <td>{dataVal?.value}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        {
                                            dataLength === 0 ? <tr>
                                                <td colSpan="100%" className="p-0">
                                                    <div className='no-found'>
                                                        <img src="../../not-found/graphreport.svg" />
                                                        <p>Graph Reports Not Found</p>
                                                    </div>
                                                </td>
                                            </tr> : ""
                                        }
                                    </tbody>
                                </Table>
                                <div className="pagination-custom">
                                    {   data.length > size ?
                                        <Pagination showTitle={false}
                                        className="pagination-data"
                                        onChange={PaginationChange}
                                        total={data.length}
                                        current={current}
                                        pageSize={size}
                                        showSizeChanger={false}
                                        itemRender={PrevNextArrow}
                                        onShowSizeChange={PerPageChange}
                                        />:""
                                    }
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Layout>
    )
}

export default GraphReports