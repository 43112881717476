import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row, Breadcrumb } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { API } from '../../App';
import { toast } from 'react-toastify';
import Layout from '../../layout/Layout';

const QuestionsAdd = () => {

    const [validate,setValidate] = useState(false)
    const navigate = useNavigate()
    const [inpData, setInpData] = useState({
        question:"",
        status:1
    })

    //top performing fund
    const [option, setOption] = useState(["",""])
    const addOption = (tag1, name) => {
        const optionTag = ""
        setOption([...option, optionTag])
    }

    const removeOption = (index) => {
        let data = [...option]
        data.splice(index, 1)
        setOption(data)
    }

    const optionChange = (e, index) => {
        let data = [...option];
        data[index] = e.target.value
        setOption(data)
    }

    const saveData = async () => {
        if (inpData === "") {
            setValidate(true)
          
        }else{
            let optCounter = 0
            option.map((optVal) => {
                if (optVal === "") {
                    optCounter++
                }
            })
            if (optCounter === 0) {
                const Form = {
                    question:inpData.question,
                    status:inpData.status,
                    option:option
                }
                const token = Cookies.get('ptjwt')
                const response = await API.post("/admin/add/question", Form, { headers: { "Authorization": `Bearer ${token}` } })
                if (response.data.status === true) {
                  toast.success("Question added succesfully")
                  navigate("/Questions")
                } else {
                  if (response.data.response_code === 429) {
                    navigate("/")
                  } else {
                    toast.error('something went wrongg')
                  }
                }
            }else{
                setValidate(true)
            }
        }
    }

    useEffect(() => {
    }, [])
    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3>Create Questions</h3>
                <Breadcrumb className="d-none d-lg-block">
                    <Breadcrumb.Item >
                        <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item >
                        <Link to="/Questions">Questions</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>Create Questions</Breadcrumb.Item>
                </Breadcrumb>
            </div>

            <div className="page-content">
                <Form noValidate validated={validate}>
                    <Row>
                        <Col xs={12}>
                            <Card className="mb-4">
                                <Card.Body>
                                    <Row className='justify-content-center'>
                                        <Col lg={9} sm={12}>
                                            <Row>
                                                <Col md='9' xs={12}>
                                                    <Form.Label htmlFor="name" >Question</Form.Label>
                                                    <Form.Control type="text" className="my-2" name='question' value={inpData.question} onChange={(e) => { setInpData({...inpData,['question']:e.target.value}) }} placeholder='Enter Question' required/>
                                                </Col>
                                                <Col lg={2} sm="12">
                                                    <Form.Label htmlFor="media_type" className='d-block mb-2'>Status</Form.Label>
                                                    <BootstrapSwitchButton onlabel='Enable' offlabel='Disable' checked={inpData.status===1?true:false} onChange={(e)=>{setInpData({...inpData,['status']:e===true?1:0})}} size="sm" width={100} />
                                                </Col>
                                                <Col xs={12}>
                                                    
                                                        {/* <Col md={11}>
                                                            <Form.Control type="text" className="my-2" name='Name' placeholder='Enter Answer' required/>
                                                        </Col>
                                                        <Col md={11}>
                                                            <Form.Control type="text" className="my-2" name='Name' placeholder='Enter Answer' required/>
                                                        </Col>
                                                        <Col md={1} className="text-center">
                                                            <Button variant='primary' className='btn-icon my-2'><i className='bx bx-plus'></i></Button>
                                                        </Col> */}
                                                        {
                                                            option.map((optVal,ind)=>{
                                                                return(
                                                                    <Row>
                                                                        <Col md={7}>
                                                                            <Form.Control type="text" className="my-2" name='Name' value={optVal} onChange={(e) => { optionChange(e, ind) }} placeholder='Enter Option' required/>
                                                                        </Col>
                                                                        {
                                                                            ind===1?
                                                                            <Col md={1} className="text-center">
                                                                                <Button variant='primary' className='btn-icon my-2' onClick={addOption}><i className='bx bx-plus'></i></Button>
                                                                            </Col>:
                                                                            (
                                                                                ind>1?
                                                                                <Col md={1} className="text-center">
                                                                                    <Button variant='danger' className='btn-icon my-2' onClick={() => { removeOption(ind) }}><i className='bx bx-minus'></i></Button>
                                                                                </Col>:""
                                                                            )
                                                                        }
                                                                    </Row>
                                                                )
                                                            })
                                                        }
                                                    
                                                    {/* <Row>
                                                        <Col md={11}>
                                                            <Form.Control type="text" className="my-2" name='Name' placeholder='Enter Answer' required/>
                                                        </Col>
                                                        <Col md={1} className="text-center">
                                                            <Button variant='danger' className='btn-icon my-2'><i className='bx bx-minus'></i></Button>
                                                        </Col>
                                                    </Row> */}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card.Body>
                                <Card.Footer className="text-end">
                                    <Button variant="primary" className="me-3" onClick={saveData}>Save</Button>
                                    <Link to="/Questions">
                                        <Button variant="secondary">Cancle</Button>
                                    </Link>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </div>
        </Layout>
    )
}

export default QuestionsAdd