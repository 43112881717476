import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row, Breadcrumb } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Layout from '../../layout/Layout';
import { API } from '../../App';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
const CategoryEdit = () => {

  const navigate = useNavigate()
  const [validate,setValidate] = useState(false)
  const {_id} = useParams()
  const [user, setUser] = useState({
    Name: "",
    Status: "",
    isGraph:"",
    craetedAt:"",
    updatedAt:"",
    id: ""
  })

  const getData = async () => {
    const token = Cookies.get('ptjwt')
    const categoryData = await API.post(`/admin/view/category/${_id}`,{},{ headers: { "Authorization": `Bearer ${token}` } } )
      if(categoryData.data.status===false){
          toast.error("something went wrong")
      }else{
          setUser({
              Name:categoryData.data.data.name,
              Status:categoryData.data.data.status,
              isGraph:categoryData.data.data.is_graph,
              craetedAt:categoryData.data.data.createdAt,
              updatedAt:categoryData.data.data.updatedAt,
              id:categoryData.data.data._id
          })
      }
  }

  const saveData = async () => {
    if (user.Name === "") {
        setValidate(true)
    } else {
      if(user.Name.match(/^[A-Za-z& ]+$/)){
        const Form = new FormData
        Form.append("name", user.Name)
        Form.append("status", user.Status)
        Form.append("isGraph", user.isGraph)
        const token = Cookies.get('ptjwt')
        const response = await API.post(`/admin/edit/category/${_id}`, Form, { headers: { "Authorization": `Bearer ${token}` } })
        if (response.data.status === true) {
          toast.success("Category updated")
          navigate(`/category/view/${_id}`)
        } else {
          if (response.data.response_code === 429) {
            navigate("/")
          } else {
            toast.error('something went wrong')
          }
        }
      }else{
        toast.error('please enter valid Category name')
      }
    }
  }

  useEffect(() => {
    getData()
  }, [])
  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3>Edit Category</h3>
        <Breadcrumb className="d-none d-lg-block">
          <Breadcrumb.Item >
            <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item >
            <Link to="/category">Category</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Edit Category</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <div className="page-content">
        <Form noValidate validated={validate}>
          <Row>
            <Col xs={12}>
              <Card className="mb-4">
                <Card.Body>
                  <Row>
                    <Col lg={4} sm={12}>
                      <Form.Label htmlFor="cate" >Name</Form.Label>
                      <Form.Control type="text" id='cate' className="my-2" name='Name' value={user.Name} onChange={(e)=>{setUser({...user,['Name']:e.target.value})}} placeholder='Enter Name' required/>
                    </Col>
                    <Col lg={2} sm="12">
                      <Form.Label htmlFor="media_type" className='d-block mb-2'>Status</Form.Label>
                      <BootstrapSwitchButton onlabel='Enable' offlabel='Disable' checked={user.Status===1?true:false} onChange={(e)=>{setUser({...user,['Status']:e===true?1:0})}} size="sm" width={100} />
                    </Col>
                    <Col lg={2} sm="12">
                      <Form.Label htmlFor="media_type" className='d-block mb-2'>Is Graph</Form.Label>
                      <BootstrapSwitchButton onlabel='Enable' offlabel='Disable' checked={user.isGraph===1?true:false} onChange={(e)=>{setUser({...user,['isGraph']:e===true?1:0})}} size="sm" width={100} />
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer className="text-end">
                  <Button variant="primary" className="me-3" onClick={saveData}>Save</Button>
                  <Link to={`/category/view/${_id}`}>
                    <Button variant="secondary">Cancle</Button>
                  </Link>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Form>
      </div>
    </Layout>
  )
}

export default CategoryEdit