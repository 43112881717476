import React, { useEffect } from 'react'
import Layout from '../layout/Layout'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { API } from '../App';

const Home = () => {
    // const navigate = useNavigate()
    // const tokenAuth = async() => {
    //     const token = Cookies.get('ptjwt')
    //     const response = await API.post("/admin/home",{},{headers:{"Authorization" : `Bearer ${token}`}})
    //     if(response.data.status===false){
    //         navigate("/")
    //     }
    // }
    useEffect(() => {
        // tokenAuth()
    }, [])
    return (
        <Layout sidebar={true}>
            <>
                <div className="page-content">
                    <Row>
                        <Col xs={12}>
                            <Card>
                                <Card.Body>
                                    <h3 className="text-center mb-0"><b>Welcome to Period Tracker</b></h3>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        </Layout>
    )
}

export default Home