import React, { useEffect, useState } from 'react'
import { Row, Col, Table, Card, Button, Form, Modal } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Layout from '../../layout/Layout';
import Pagination from 'rc-pagination'
import Cookies from 'js-cookie'
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import { toast } from 'react-toastify';
import $ from 'jquery'
import Swal from 'sweetalert2'
import { API } from '../../App';

const Questions = () => {
    const [show, setShow] = useState(false);
    const [view,setView] = useState({
        question:"",
        option:[]
    })
    const QuestionHandleClose = () => {
        setView({
            question:"",
            option:[]
        })
        setShow(false);
    }
    const QuestionHandleShow = (viewData) => {
        setView({
            question:viewData?.question,
            option:viewData?.option
        })
        setShow(true);
    }
    const [iconcoror, Seticoncoror] = useState("id_up")
    const [dataLength, setDataLength] = useState(1)
    const [data, setData] = useState([])
    const [sData, setSData] = useState([])
    const [oid,setOid] = useState([])
    const navigate = useNavigate()
    const getData = async () => {
        const token = Cookies.get('ptjwt')
        const response = await API.post("/admin/get/question",{},{ headers : { "Authorization" : `Bearer ${token}`}})
        if (response.data.status === false) {
            if (response.data.response_code === 429) {
                navigate("/")
            } else {
              setData([])
              setSData([])
              setOid([])
              setDataLength(0)
            }
        } else {
            setData(response.data.data.data)
            setSData(response.data.data.data)
            setOid(response.data.data.id)
            setDataLength(1)
        }
    }

    const changeStatus = async (id, e) => {
        var Status;
        Status = (e === true) ? 1 : 0
        const Form = new FormData()
        // console.log(Status)
        Form.append('id', id)
        Form.append('status', Status)
        const token = Cookies.get('ptjwt')
        const update = await API.post(`/admin/question/status/${id}`, Form, { headers: { "Authorization": `Bearer ${token}` } })
        if (update.data.status === false) {
            if (update.data.response_code === 429) {
                navigate("/")
            } else {
                toast.error('Something Went Wrong')
            }
        }else{
            var statusData = data.findIndex(x => x._id ===id);
            data[statusData].status=Status
            toast.success('Status Updated')
        }
    }

    const deleteUser = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-success',
                cancelButton: 'btn btn-danger me-2'
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, Delete it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then(async (result) => {
            if (result.isConfirmed) {
                const token = Cookies.get('ptjwt')
                const delCategory = await API.post(`/admin/delete/question/${id}`, {}, { headers: { "Authorization": `Bearer ${token}` } })
                if (delCategory.data.status === false) {
                    if (delCategory.data.response_code === 429) {
                        navigate("/")
                    } else {
                        swalWithBootstrapButtons.fire(
                            'Cancelled',
                            `${delCategory.data.response_message}`,
                            'error'
                        )
                    }
                } else {
                    getData()
                    setCurrent(1)
                }
    
            }
        })
    }

    const statusDropdown = (e) => {
        if(e.target.value==="1"){
            const statData = [...sData].filter(val=>{
                return val.status===1
            })
            setData(statData)
            if(statData.length===0){
                setDataLength(0)
            }else{
                setDataLength(1)
            }
        }else if(e.target.value==="0"){
            const statData = [...sData].filter(val=>{
                return val.status===0
            })
            setData(statData)
            if(statData.length===0){
                setDataLength(0)
            }else{
                setDataLength(1)
            }
        }else{
            setData(sData)
            if(sData.length===0){
                setDataLength(0)
            }else{
                setDataLength(1)
            }
        }
        setCurrent(1)
    }

    //serach

    const requestSearch = (searchedVal) => {
        const filteredRows = data.filter((row) => {
            return row.question.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());

        });
        if (searchedVal.length < 1) {
            $('#Data_not_found').css({ display: "none" })
            getData()
        }
        else {
            if (filteredRows.length === 0) {
                const filteredRows1 = sData.filter((row) => {
                    return row.question.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
                });
                if (filteredRows1.length === 0) {
                    setData(filteredRows)
                    setDataLength(0)
                }
                else {
                    $('#remove_tr').empty()
                    setData(filteredRows1)
                    setCurrent(1)
                    setDataLength(1)
                }
            }
            else {
                const filteredRows1 = sData.filter((row) => {
                    return row.question.toString().toLowerCase().includes(searchedVal.toString().toLowerCase());
                });
                if (filteredRows1.length === 0) {
                    $('#remove_tr').empty()
                    $('#remove_tr').append('<td colSpan="100%" class="p-0"><div class="no-found">No Records Found</div></td>')
                    setData(filteredRows)

                }
                else {
                    $('#remove_tr').empty()
                    setData(filteredRows1)
                    setCurrent(1)
                }
                // setData(filteredRows)
                // setCurrent(1)
            }
        }
    };

    // Paggintion Code // 
    const [perPage, setPerPage] = useState(10);
    const [size, setSize] = useState(perPage);
    const [current, setCurrent] = useState(1);

    const wetData = (current, pageSize) => {
        return data.slice((current - 1) * pageSize, current * pageSize);
    };

    const PerPageChange = (value) => {
        setSize(value);
        const newPerPage = Math.ceil(data.length / value);
        if (current > newPerPage) {
            setCurrent(newPerPage);
        }
    }

    const PaginationChange = (page, pageSize) => {
        setCurrent(page);
        setSize(pageSize)
    }


    const PrevNextArrow = (current, type, originalElement) => {
        if (type === 'prev') {
            return <button className='paggination-btn'>Previous</button>;
        }
        if (type === 'next') {
            return <button className='paggination-btn'>Next</button>;
        }
        return originalElement;
    }

    const sorting = (col, type = "string", order, e) => {
        Seticoncoror(e.target.id)
        if (order === "ASC") {
          const sorted = [...data].sort((a, b) =>
            a[col] > b[col] ? 1 : -1
          );
          if (iconcoror !== e.target.id) {
            setData(sorted)
          }
        }
        if (order === "DSC") {
          const sorted = [...data].sort((a, b) =>
            a[col] < b[col] ? 1 : -1
          );
          if (iconcoror !== e.target.id) {
            setData(sorted)
          }
        }
    }

    useEffect(() => {
        getData()
    }, [])
    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3 className="my-1">Questions</h3>
                <Row className="justify-content-end align-items-center">
                    <Col md="auto" xs={12}>
                        <Form.Control type="text" name="" id="" onChange={(e) => requestSearch(e.target.value)} placeholder="Search Questions Name" className="my-1" />
                    </Col>
                    <Col md="auto" xs={4}>
                        <Form.Select id='mcat' className='my-1' name='Status' onChange={statusDropdown}>
                            <option defaultValue={""} value="">Select Status</option>
                            <option value={1} key={1}>{"Enable"}</option>
                            <option value={0} key={2}>{"Disable"}</option>
                        </Form.Select>
                    </Col>
                    <Col md="auto" xs={6}>
                        <Form.Select className="my-1" onChange={e => { setSize(Number(e.target.value)); setCurrent(1) }}>
                        {
                            [10, 25, 50, 100].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                {pageSize}
                            </option>
                            ))
                        }
                        </Form.Select>
                    </Col>
                    <Col md="auto" xs={6}>
                        <Link to="/QuestionsAdd">
                            <Button variant="primary" className='my-1' value="create">Add New</Button>
                        </Link>
                    </Col>
                </Row>
            </div>
            <div className="page-content">
                <Row>
                    <Col xs={12}>
                        <Card>
                            <Card.Body>
                                <Table bordered responsive>
                                    <thead>
                                        <tr>
                                            <th width="5%">
                                            <div className='table-sort-filter justify-content-center'>
                                                No.
                                                {/* <span className='table-sort'>
                                                    <div className={`sort-down ${iconcoror === "id_down" ? "active" : ""}`} id="id_down" onClick={(e) => { sorting('_id', "", "DSC", e) }} ></div>
                                                    <div className={`sort-up ${iconcoror === "id_up" ? "active" : ""}`} id="id_up" onClick={(e) => { sorting('_id', "", "ASC", e) }} ></div>
                                                </span> */}
                                            </div>
                                            </th>
                                            <th width="65%">
                                            <div className='table-sort-filter'>
                                                Questions
                                                <span className='table-sort'>
                                                    <div className={`sort-down ${iconcoror === "question_down" ? "active" : ""}`} id="question_down" onClick={(e) => { sorting('question', "", "DSC", e) }} ></div>
                                                    <div className={`sort-up ${iconcoror === "question_up" ? "active" : ""}`} id="question_up" onClick={(e) => { sorting('question', "", "ASC", e) }} ></div>
                                                </span>
                                            </div>    
                                            </th>
                                            <th width="10%" className='text-center'>Option</th>
                                            <th width="10%">
                                            <div className='table-sort-filter justify-content-center'>
                                                Status
                                                <span className='table-sort'>
                                                    <div className={`sort-down ${iconcoror === "status_down" ? "active" : ""}`} id="status_down" onClick={(e) => { sorting('status', "", "DSC", e) }} ></div>
                                                    <div className={`sort-up ${iconcoror === "status_up" ? "active" : ""}`} id="status_up" onClick={(e) => { sorting('status', "", "ASC", e) }} ></div>
                                                </span>
                                            </div>
                                            </th>
                                            <th width="10%" className='text-center'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            wetData(current, size).map((dataVal,ind)=>{
                                                return(
                                                    <tr key={ind}>
                                                        {/* <td className='text-center'>1</td> */}
                                                        <td className='text-center'>{(current === 1) ? ind + 1 : current * size + ind + 1 - size}</td>
                                                        {/* <td className='text-center'>{oid.indexOf(dataVal._id)+1}</td> */}
                                                        <td>{dataVal.question}</td>
                                                        <td className='text-center'><Button variant="outline-info" size="sm" className="btn-icon" onClick={()=>{QuestionHandleShow(dataVal)}}><i className='bx bx-show' ></i></Button></td>
                                                        <td className='text-center'><BootstrapSwitchButton checked={dataVal.status===1?true:false} onChange={(e) => { changeStatus(dataVal._id, e) }} onlabel='Enable' offlabel='Disable' size="sm" width={100} /></td>
                                                        <td className='text-center'>
                                                            <Link to={`/question/view/${dataVal._id}`}>
                                                                <Button variant="outline-warning" size="sm" className="me-2 btn-icon"><i className='bx bx-show'></i></Button>
                                                            </Link>
                                                            <Button variant="outline-danger" size="sm" onClick={() => deleteUser(dataVal._id)} className="btn-icon"><i className='bx bx-trash-alt' ></i></Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                        {
                                            dataLength === 0 ? <tr>
                                                <td colSpan="100%" className="p-0">
                                                    <div className='no-found'>
                                                        <img src="../../not-found/category.svg" />
                                                        <p>Data Not Found</p>
                                                    </div>
                                                </td>
                                            </tr> : ""
                                        }
                                    </tbody>
                                </Table>
                                <div className="pagination-custom">
                                {
                                data.length > size ?
                                <Pagination showTitle={false}
                                    className="pagination-data"
                                    onChange={PaginationChange}
                                    total={data.length}
                                    current={current}
                                    pageSize={size}
                                    showSizeChanger={false}
                                    itemRender={PrevNextArrow}
                                    onShowSizeChange={PerPageChange}
                                />:""
                                }
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </div>
            <Modal show={show} size="lg" onHide={QuestionHandleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Question</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h6>{view?.question}</h6>
                    <ul className='mb-0'>
                        {
                            view?.option?.map((dataVal,ind)=>{
                                return(
                                    <li>{dataVal?.option}</li>
                                )
                            })
                        }
                    </ul>
                </Modal.Body>
            </Modal>
        </Layout>
    )
}

export default Questions