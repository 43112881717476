import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row } from 'react-bootstrap'
import { Link, useNavigate, useParams } from 'react-router-dom'
import Layout from '../../layout/Layout';
import Fancybox from '../../Component/FancyBox';
import { API } from '../../App';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';

const SubCategoryView = () => {

    const {_id} = useParams()
    const navigate = useNavigate()
    const [subCategory,SetSubCategory] = useState("")
    const [data,setData] = useState([])
    const [user,setUser] = useState({
        category:"",
        name:"",
        icon:"",
        status:"",
        header:"",
        craetedAt:"",
        updatedAt:"",
        id:""
    })
    const getData = async(id) => {
        const token = Cookies.get('ptjwt')
        const subCategoryData = await API.post(`/admin/view/sub_category/${id}`,{} , {headers : {"Authorization" : `Bearer ${token}`}})
        if(subCategoryData.data.status===false){
            if (subCategoryData.data.response_code === 429) {
                navigate("/")
            } else {
                toast.error('something went wrongss')
            }
        }else{
            setUser({
                category:subCategoryData.data.data.category_id.name,
                name:subCategoryData.data.data.name,
                status:subCategoryData.data.data.status,
                icon:subCategoryData.data.data.icon,
                header:subCategoryData.data.data.header,
                craetedAt:subCategoryData.data.data.createdAt,
                updatedAt:subCategoryData.data.data.updatedAt,
                id:subCategoryData.data.data._id
            })
            SetSubCategory(subCategoryData.data.data._id)
        }
        const subCategory = await API.post("/admin/sub_category" , {} ,{headers : {"Authorization" : `Bearer ${token}`}})
        if (subCategory.data.status === false) {
            if (subCategory.data.response_code === 429) {
                navigate("/")
            } else {
                toast.error("Something Went Wrongs")
            }
        } else {
            setData(subCategory.data.data.data)
        }
    }

    const getCategory = (e) => {
        SetSubCategory(e.target.value)
        getData(e.target.value)
    }

    useEffect(()=>{
        getData(_id)
      },[])
    return (
        <Layout sidebar={true}>
            <div div className="page-heading">
                <h3><Link to="/subcategory" className='btn btn-primary btn-icon me-3'><i class='bx bxs-left-arrow-alt'></i></Link><span>View Sub Category</span></h3>
                <Row className="align-items-center">
                    <Col md="auto" xs={6}>
                        <Form.Select className="my-1" onChange={(e)=>{getCategory(e)}}>
                            {
                                data.map((dataVal,ind) => {
                                    return (
                                        <option selected={dataVal?._id === user.id ? true : false} value={dataVal._id} key={ind}>{dataVal.name}</option>
                                    )
                                })
                            }
                        </Form.Select>
                    </Col>
                    <Col md="auto" xs={6}>
                        <Link to={`/subcategory/edit/${subCategory}`}>
                            <Button variant="primary" value="edit">Edit</Button>
                        </Link>
                    </Col>
                </Row>
            </div>
            <div className='page-content'>
                <Card>
                    <Card.Body>
                        <Row>
                            <Col md={3}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Category</p>  <span>{user?.category}</span>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Name</p>  <span>{user?.name}</span>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Icon</p>
                                    <Fancybox>
                                        <a data-fancybox="gallery" href={user?.icon}>
                                            <img src={user?.icon} className="hv-40" alt="Icon" />
                                        </a>
                                    </Fancybox>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Header</p>  <span>{user.header?user.header:"N/A"}</span>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Status</p>  <span>{user.status===1?"Active":"InActive"}</span>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Created At</p>  <span>{user.craetedAt}</span>
                                </div>
                            </Col>
                            <Col md={3}>
                                <div className='mb-4'>
                                    <p className='mb-0 fw-bold'>Updated At</p>  <span>{user.updatedAt}</span>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        </Layout>
    )
}

export default SubCategoryView