import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Row, Breadcrumb } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import Layout from '../../layout/Layout';
import { toast } from 'react-toastify';
import { API } from '../../App';
import Cookies from 'js-cookie'

const CategoryAdd = () => {

  //code
  const [validate,setValidate] = useState(false)
  const navigate = useNavigate()
  const [inpData, setInpData] = useState({
    Name: "",
    Status:1,
    isGraph:0
  })

  const saveData = async (event) => {
    if (inpData.Name === "") {
        setValidate(true)
    }else {
      if(inpData.Name.match(/^[A-Za-z& ]+$/)){
        const Form = new FormData()
        Form.append('name', inpData.Name)
        Form.append('status', inpData.Status)
        Form.append('isGraph', inpData.isGraph)
        const token = Cookies.get('ptjwt')
        const response = await API.post("/admin/add/category", Form, { headers: { "Authorization": `Bearer ${token}` } })
        if (response.data.status === true) {
          toast.success("Category created")
          navigate("/category")
        } else {
          if (response.data.response_code == 429) {
            navigate("/")
          } else {
            toast.error('something went wrong')
          }
        }
      }else{
        toast.error('please enter valid Category name')
      }
    }
  }

  useEffect(() => {
  }, [])
  return (
    <Layout sidebar={true}>
      <div className="page-heading">
        <h3>Create Category</h3>
        <Breadcrumb className="d-none d-lg-block">
          <Breadcrumb.Item >
            <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item >
            <Link to="/category">Category</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>Create Category</Breadcrumb.Item>
        </Breadcrumb>
      </div>

      <div className="page-content">
        <Form noValidate validated={validate}>
          <Row>
            <Col xs={12}>
              <Card className="mb-4">
                <Card.Body>
                  <Row>
                    <Col lg={4} sm={12}>
                      <Form.Label htmlFor="cate" >Name</Form.Label>
                      <Form.Control type="text" id='cate' className="my-2" name='Name' value={inpData.Name} onChange={(e)=>{setInpData({...inpData,['Name']:e.target.value})}} placeholder='Enter Name'  required/>
                    </Col>
                    <Col lg={2} sm="12">
                      <Form.Label htmlFor="media_type" className='d-block mb-2'>Status</Form.Label>
                      <BootstrapSwitchButton onlabel='Enable' offlabel='Disable' checked={inpData.Status===1?true:false} onChange={(e)=>{setInpData({...inpData,['Status']:e===true?1:0})}} size="sm" width={100} />
                    </Col>
                    <Col lg={2} sm="12">
                      <Form.Label htmlFor="media_type" className='d-block mb-2'>Is Graph</Form.Label>
                      <BootstrapSwitchButton onlabel='Enable' offlabel='Disable' checked={inpData.isGraph===1?true:false} onChange={(e)=>{setInpData({...inpData,['isGraph']:e===true?1:0})}} size="sm" width={100} />
                    </Col>
                  </Row>
                </Card.Body>
                <Card.Footer className="text-end">
                  <Button variant="primary" className="me-3" onClick={saveData}>Save</Button>
                  <Link to="/category">
                    <Button variant="secondary">Cancle</Button>
                  </Link>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Form>
      </div>
    </Layout>
  )
}

export default CategoryAdd